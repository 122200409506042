import React from "react";
import { Link } from "gatsby";
import Main from "components/layout/Main";
import HeaderSkew from "components/HeaderSkew";

import Block from "components/Block";
import backgroundOverlay from "assets/images/header_skew/header-404.jpg";
import JumbotronCard from "components/JumbotronCard";

export default function NotFoundPage() {
  const title = "Page not Found";

  return (
    <Main page={"fqa"} pagetitle={title}>
      <HeaderSkew
        title={title}
        subtitle=""
        url="faq"
        background={backgroundOverlay}
        backgroundColor="404"
      >
        <JumbotronCard type="white-spaced-large">
          <>
            <h2>Can’t find what you are looking for?</h2>
            <p style={{ maxWidth: "401px" }} style={{}}>
              Click the button below to go to our home page or call us at{" "}
              <a
                href="tel:1-844-loanpal"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "#555555",
                  textDecoration: "underline",
                  whiteSpace: "nowrap",
                }}
              >
                1-844-LOANPAL
              </a>{" "}
              for assistance.
            </p>
            <Link className="button button--dark-light" to="/">
              HOME PAGE
            </Link>
          </>
        </JumbotronCard>
      </HeaderSkew>

      <Block layer="1" name="" guides={1} appearance="404" />
    </Main>
  );
}
